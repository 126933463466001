<template>
  <div class="footer d-flex flex-fill">
    <div class="container d-flex flex-wrap">
      <div class="footer-main d-flex flex-column col-lg-6 col-md-12">
      </div>
        <div class="logo">
          <div>
            <a href="https://www.ciro.ca/" target="_blank"><img src="@/assets/images/ciro.svg" class="ciro-icon" style="filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(273deg) brightness(111%) contrast(101%);" alt="" /></a>
          </div>
          <div class="ciro-link">
            <a href="https://www.ciro.ca/office-investor/know-your-advisor-advisor-report" target="_blank">Generate a CIRO <i>Advisor Report</i></a>
          </div>
        </div>
        <div class="address my-2 d-flex flex-wrap">
        <div class="logo">
          <a href="https://www.cipf.ca/" target="_blank"><img src="@/assets/images/cipf.svg" class="cipf-icon m-3" alt="" /></a>
        </div>
      </div>
    </div>
  </div>

  <div class="footer d-flex flex-fill">
    <div class="container d-flex flex-wrap footer-petersco">
        Peters & Co. Limited
    </div>
  </div>


</template>
<script lang="ts">
export default {
  name: "vfooter",
  setup() {},
};
</script>
