import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { IPermission } from "@/store/common/Types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/publications",
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("@/views/ForgotPassword.vue"),
  },
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import("@/views/ChangePassword.vue"),
    meta: {
      permissions: [],
    },
  },
  {
    path: "/resetpassword",
    name: "resetpassword",
    component: () => import("@/views/ChangePassword.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("@/views/TermsAndCondition.vue"),
    meta: {
      permissions: [],
    },
  },
  {
    path: "/sharedocument/:docId",
    name: "sharedocument",
    component: () => import("@/views/ShareDocument.vue"),
  },
  {
    path: "/publications",
    name: "documents",
    component: () => import("@/views/Documents.vue"),
    meta: {
      permissions: ["DocumentView"],
    },
  },
  {
    path: "/advancedsearch",
    name: "documentsearch",
    component: () => import("@/views/DocumentSearch.vue"),
    meta: {
      permissions: ["DocumentView"],
    },
  },
  {
    path: "/useractivity",
    name: "useractivity",
    component: () => import("@/views/UserActivity.vue"),
    meta: {
      permissions: ["UserActivityView"],
    },
  },
  {
    path: "/companies",
    name: "companies",
    component: () => import("@/views/Companies.vue"),
    meta: {
      permissions: [],
    },
  },
  {
    path: "/sectors",
    name: "sectors",
    component: () => import("@/views/Sectors.vue"),
    meta: {
      permissions: ["SectorCreate", "SectorEdit"],
    },
  },
  {
    path: "/publicationtypes",
    name: "publicationtypes",
    component: () => import("@/views/PublicationTypes.vue"),
    meta: {
      permissions: ["PublicationTypeCreate", "PublicationTypeEdit"],
    },
  },
  {
    path: "/roles",
    name: "roles",
    component: () => import("@/views/Roles.vue"),
    meta: {
      permissions: ["RoleCreate", "RoleEdit"],
    },
  },
  {
    path: "/emailtemplates",
    name: "emailtemplates",
    component: () => import("@/views/EmailTemplates.vue"),
    meta: {
      permissions: ["EmailTemplateCreate", "EmailTemplateEdit"],
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import("@/views/Users.vue"),
    meta: {
      permissions: ["UserView"],
    },
  },
  {
    path: "/emailcomposer",
    name: "emailcomposer",
    component: () => import("@/views/EmailComposer.vue"),
    meta: {
      permissions: ["CanSendEmail"],
    },
  },
  {
    path: "/holidays",
    name: "holidays",
    component: () => import("@/views/Holidays.vue"),
    meta: {
      permissions: ["HolidayCreate", "HolidayEdit"],
    },
  },
  {
    path: "/eventDownloads",
    name: "eventDownloads",
    component: () => import("@/views/EventDownloads.vue"),
    meta: {
      permissions: ["EventDownloadEdit"],
    },
  },
  { path: '/eventDownloads/:linkName',
    name: 'eventFile', 
    component: () => import("@/views/EventFile.vue"), 
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
    refresh_token: JwtService.getRefreshToken(),
  });

  if (
    store.getters.isUserAuthenticated &&
    Object.keys(store.getters.currentUser).length === 0
  ) {
    store.dispatch(Actions.LOAD_LOGIN_USER);
    store.dispatch(Actions.LOAD_PERMISSION);
  }

  if (
    !store.getters.isUserAuthenticated &&
     typeof to.meta.permissions !== 'undefined' &&
    (to.meta.permissions as Array<string>).length === 0
  ) {
    return next("/login");
  }

  if (
    to.meta.permissions &&
    (to.meta.permissions as Array<string>).length > 0
  ) {
    const permission = store.getters.getPermission as IPermission;
    if (
      Object.keys(permission).length === 0 ||
      !permission.Permissions.some((p) =>
        (to.meta.permissions as Array<string>).includes(p)
      )
    ) {
      return next("/login");
    }
  }
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
