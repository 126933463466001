import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/PetersLogo.png'


const _hoisted_1 = { class: "navbar navbar-expand-sm navbar-toggleable-sm header-nav" }
const _hoisted_2 = { class: "container d-flex justify-content-center" }
const _hoisted_3 = { class: "col-8 d-flex justify-content-end align-items-center" }
const _hoisted_4 = { class: "p-2" }
const _hoisted_5 = { class: "nav navbar-nav flex-row float-right" }
const _hoisted_6 = {
  key: 0,
  class: "nav-item dropdown d-none d-md-block"
}
const _hoisted_7 = {
  class: "nav-link nav-link-welcome dropdown-toggle",
  href: "#",
  id: "navbarDropdown",
  role: "button",
  "data-bs-toggle": "dropdown",
  "aria-expanded": "false"
}
const _hoisted_8 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarDropdown"
}
const _hoisted_9 = {
  class: "bg-white",
  id: "menuBar"
}
const _hoisted_10 = { class: "container" }
const _hoisted_11 = { class: "navbar-collapse collapse d-sm-flex" }
const _hoisted_12 = { class: "nav navbar-nav nav-flex" }
const _hoisted_13 = {
  key: 0,
  class: "nav-item"
}
const _hoisted_14 = {
  key: 1,
  class: "nav-item"
}
const _hoisted_15 = {
  key: 2,
  class: "nav-item"
}
const _hoisted_16 = {
  key: 3,
  class: "nav-item"
}
const _hoisted_17 = {
  key: 4,
  class: "nav-item"
}
const _hoisted_18 = {
  key: 5,
  class: "nav-item dropdown"
}
const _hoisted_19 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarDropdown"
}
const _hoisted_20 = {
  key: 6,
  class: "nav-item dropdown d-md-none d-sm-block"
}
const _hoisted_21 = {
  class: "dropdown-menu",
  "aria-labelledby": "navbarDropdown"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[6] || (_cache[6] = _createStaticVNode("<div class=\"col-4 d-flex align-items-center\"><a href=\"https://www.petersco.com/\" class=\"navbar-brand\"><img src=\"" + _imports_0 + "\" class=\"nav-logo\" alt=\"\"></a><button class=\"navbar-toggler\" type=\"button\" data-bs-toggle=\"collapse\" data-bs-target=\".navbar-collapse\" aria-controls=\"navbarSupportedContent\" aria-expanded=\"false\" aria-label=\"Toggle navigation\"><span class=\"navbar-toggler-icon\"></span></button></div>", 1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("ul", _hoisted_5, [
              ($setup.isLoggedIn)
                ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
                    _createElementVNode("a", _hoisted_7, _toDisplayString($setup.userName), 1),
                    _createElementVNode("ul", _hoisted_8, [
                      _createVNode(_component_router_link, {
                        class: "dropdown-item",
                        to: "/changepassword"
                      }, {
                        default: _withCtx(() => _cache[5] || (_cache[5] = [
                          _createTextVNode("Change Password")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("li", null, [
                        _createElementVNode("a", {
                          class: "dropdown-item clickable",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.signOut && $setup.signOut(...args)))
                        }, "Log Out")
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("ul", _hoisted_12, [
            ($setup.canViewDocs)
              ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
                  _createVNode(_component_router_link, {
                    class: "nav-link top-menu",
                    to: "/publications"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Latest Publications")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            ($setup.canViewDocs)
              ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
                  _createVNode(_component_router_link, {
                    class: "nav-link top-menu",
                    to: "/advancedsearch"
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Advanced Search")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            ($setup.canSendEmail)
              ? (_openBlock(), _createElementBlock("li", _hoisted_15, [
                  _createVNode(_component_router_link, {
                    class: "nav-link top-menu",
                    to: "/emailcomposer"
                  }, {
                    default: _withCtx(() => _cache[9] || (_cache[9] = [
                      _createTextVNode("Energy Facts")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            ($setup.canAccessCRM)
              ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
                  _createElementVNode("div", {
                    class: "nav-link top-menu clickable",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showCrmModal = true))
                  }, " CRM Sync ")
                ]))
              : _createCommentVNode("", true),
            ($setup.canViewUserActivity)
              ? (_openBlock(), _createElementBlock("li", _hoisted_17, [
                  _createVNode(_component_router_link, {
                    class: "nav-link top-menu",
                    to: "/useractivity"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode("User Activity")
                    ])),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            (
              $setup.canViewEmailTemplates ||
              $setup.canViewUsers ||
              $setup.canViewRoles ||
              $setup.canViewCompanies ||
              $setup.canViewSectors ||
              $setup.canViewPublicationTypes ||
              $setup.canViewEventDownloads ||
              $setup.canViewHolidays
            )
              ? (_openBlock(), _createElementBlock("li", _hoisted_18, [
                  _cache[19] || (_cache[19] = _createElementVNode("a", {
                    class: "nav-link top-menu dropdown-toggle",
                    href: "#",
                    id: "navbarDropdown",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false"
                  }, " Administration ", -1)),
                  _createElementVNode("ul", _hoisted_19, [
                    ($setup.canViewEmailTemplates)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 0,
                          class: "dropdown-item",
                          to: "/emailtemplates"
                        }, {
                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                            _createTextVNode("Email Templates")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewUsers)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 1,
                          class: "dropdown-item",
                          to: "/users"
                        }, {
                          default: _withCtx(() => _cache[12] || (_cache[12] = [
                            _createTextVNode("User Management")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewCompanies)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 2,
                          class: "dropdown-item",
                          to: "/companies"
                        }, {
                          default: _withCtx(() => _cache[13] || (_cache[13] = [
                            _createTextVNode("Company Management")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewRoles)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 3,
                          class: "dropdown-item",
                          to: "/roles"
                        }, {
                          default: _withCtx(() => _cache[14] || (_cache[14] = [
                            _createTextVNode("Roles")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewSectors)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 4,
                          class: "dropdown-item",
                          to: "/sectors"
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createTextVNode("Sectors")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewPublicationTypes)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 5,
                          class: "dropdown-item",
                          to: "/publicationtypes"
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode("Publication Types")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewHolidays)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 6,
                          class: "dropdown-item",
                          to: "/holidays"
                        }, {
                          default: _withCtx(() => _cache[17] || (_cache[17] = [
                            _createTextVNode("Holidays")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    ($setup.canViewEventDownloads)
                      ? (_openBlock(), _createBlock(_component_router_link, {
                          key: 7,
                          class: "dropdown-item",
                          to: "/eventDownloads"
                        }, {
                          default: _withCtx(() => _cache[18] || (_cache[18] = [
                            _createTextVNode("Event Downloads")
                          ])),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            ($setup.isLoggedIn)
              ? (_openBlock(), _createElementBlock("li", _hoisted_20, [
                  _cache[21] || (_cache[21] = _createElementVNode("a", {
                    class: "nav-link dropdown-toggle",
                    href: "#",
                    id: "navbarDropdown",
                    role: "button",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false"
                  }, " Profile ", -1)),
                  _createElementVNode("ul", _hoisted_21, [
                    _createVNode(_component_router_link, {
                      class: "dropdown-item",
                      to: "/changepassword"
                    }, {
                      default: _withCtx(() => _cache[20] || (_cache[20] = [
                        _createTextVNode("Change Password")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", {
                        class: "dropdown-item clickable",
                        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.signOut && $setup.signOut(...args)))
                      }, "Log Out")
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        ($setup.showCrmModal)
          ? (_openBlock(), _createBlock(_component_modal, {
              key: 0,
              onCancel: _cache[3] || (_cache[3] = ($event: any) => ($setup.showCrmModal = false)),
              onConfirm: _cache[4] || (_cache[4] = ($event: any) => ($setup.confirmCrmSync())),
              crmsync: true
            }, {
              header: _withCtx(() => _cache[22] || (_cache[22] = [
                _createElementVNode("div", { class: "title" }, "Confirm Sync", -1)
              ])),
              body: _withCtx(() => _cache[23] || (_cache[23] = [
                _createElementVNode("div", { class: "d-flex justify-content-center" }, [
                  _createElementVNode("div", { class: "message" }, " Are you sure you want to manually sync with Sugar CRM? ")
                ], -1)
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}