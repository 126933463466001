import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap";

import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import { BootstrapIconsPlugin } from "bootstrap-icons-vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/assets/font/stylesheet.css";
import "@/assets/css/main.css";
import VueFeather from "vue-feather";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Paginate from "vuejs-paginate-next";
import Popper from "vue3-popper";
import { AgGridVue } from "ag-grid-vue3";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { vue3Debounce } from "vue-debounce";

import ApiService from "@/core/services/ApiService";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(BootstrapIconsPlugin);
app.component("paginate", Paginate);
app.component("Datepicker", Datepicker);
app.component("v-select", vSelect);
app.component("ag-grid-vue", AgGridVue);
app.component("VueFeather", VueFeather);
app.component("Popper", Popper);
app.directive("debounce", vue3Debounce({ lock: true }));

ApiService.init(app);

app.mount("#app");
