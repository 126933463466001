import {
  IDocument,
  IDocumentModule,
  IPublicationType,
  IDocumentSearchParam,
  IUser,
  IDocumentResult,
  IMinimalDocument,
  IMinimalSearchParam,
  ISearchResult,
} from "@/store/common/Types";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";

@Module
export default class DocumentModule
  extends VuexModule
  implements IDocumentModule
{
  errors = {};
  trustTypeId = "";
  bookTypeId = "";
  chapterTypeId = "";
  annualsTypeId = "";
  estimateTablesTypeId = "";
  recentTypeDocs = [] as Array<IDocument>;
  searchWord = "";
  searchResult = {} as IDocumentResult;
  searching = false;
  logicSearch = false;
  currentDocument = {} as IDocument;
  documentId = {};
  limitedDownload = false;
  MinimalDocuments = {} as ISearchResult<IMinimalDocument>;

  /**
   * Get document errors
   * @returns array
   */
  get getDocumentErrors() {
    return this.errors;
  }

  /**
   * Get newly-created document ID
   * @returns string
   */
  get getDocumentId() {
    return this.documentId;
  }

  /**
   * Get recent updated type docs
   * @returns array
   */
  get getRecentUpdatedTypeDocs(): Array<IDocument> {
    return this.recentTypeDocs;
  }

  /**
   * Get search result
   * @returns IDocumentResult
   */
  get getSearchResult(): IDocumentResult {
    return this.searchResult;
  }

  /**
   * Get searching flag
   * @returns boolean
   */
  get getSearchingFlag() {
    return this.searching;
  }

  /**
   * Get document
   * @returns IDocument
   */
  get getDocument(): IDocument {
    return this.currentDocument;
  }

  /**
   * Get document search word
   * @returns string
   */
  get getDocumentSearchWord(): string {
    return this.searchWord;
  }

  /**
   * Get document logic search flag
   * @returns boolean
   */
  get getDocumentSearchFlag(): boolean {
    return this.logicSearch;
  }

  /**
   * Get document limited download flag
   * @returns boolean
   */
  get getLimitedDownload(): boolean {
    return this.limitedDownload;
  }

  /**
   * Get book publication type ID
   * @returns string
   */
  get getBookPublicationTypeId(): string {
    return this.bookTypeId;
  }

  /**
   * Get chapter publication type ID
   * @returns string
   */
  get getChapterPublicationTypeId(): string {
    return this.chapterTypeId;
  }

  /**
   * Get minimal documents
   * @returns ISearchResult<IMinimalDocument>
   */
  get getMinimalDocuments(): ISearchResult<IMinimalDocument> {
    return this.MinimalDocuments;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DOCUMENT_ID](documentId: string) {
    this.documentId = documentId;
  }

  @Mutation
  [Mutations.SET_RECENT_UPDATED_TYPE_DOCS](items) {
    this.recentTypeDocs = items;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_PUBLICATION_TYPES](items) {
    if (!items) {
      return;
    }
    this.trustTypeId = items.find(function (type: IPublicationType) {
      return type.name === "Company Update";
    })!.publicationTypeId;
    this.annualsTypeId = items.find(function (type: IPublicationType) {
      return type.name === "Annuals";
    })!.publicationTypeId;
    this.estimateTablesTypeId = items.find(function (type: IPublicationType) {
      return type.name === "Estimate Tables";
    })!.publicationTypeId;
    this.bookTypeId = items.find(function (type: IPublicationType) {
      return type.name === "Book";
    })!.publicationTypeId;
    this.chapterTypeId = items.find(function (type: IPublicationType) {
      return type.name === "Chapter";
    })!.publicationTypeId;
  }

  @Mutation
  [Mutations.SET_SEARCHING_FLAG](flag: boolean) {
    this.searching = flag;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_SEARCH_RESULT](items: IDocumentResult) {
    this.searchResult = items;
    this.searching = false;
  }

  @Mutation
  [Mutations.SET_DOCUMENT](document: IDocument) {
    this.currentDocument = document;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_SEARCH_WORD](word: string) {
    this.searchWord = word;
  }

  @Mutation
  [Mutations.SET_DOCUMENT_SEARCH_FLAG](flag: boolean) {
    this.logicSearch = flag;
  }

  @Mutation
  [Mutations.SET_LIMITED_DOWNLOAD](flag: boolean) {
    this.limitedDownload = flag;
  }

  @Mutation
  [Mutations.SET_MINIMAL_DOCUMENTS](data: ISearchResult<IMinimalDocument>) {
    this.MinimalDocuments = data;
  }

  @Action
  [Actions.LOAD_RECENT_PUBLICATIONS]() {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const query = "latestpublications";
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RECENT_UPDATED_TYPE_DOCS, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading recently updated publications.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_RECENT_BOOKS]() {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const query = "latestbook";
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RECENT_UPDATED_TYPE_DOCS, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading recently updated books and chapters.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_RECENT_ANNUALS]() {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!this.annualsTypeId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error loading recently updated annuals.",
      ]);
      return;
    }
    ApiService.setHeader();
    const query = "latestannuals";
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RECENT_UPDATED_TYPE_DOCS, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading recently updated annuals.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_RECENT_TABLES]() {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!this.estimateTablesTypeId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error loading recently updated estimate tables.",
      ]);
      return;
    }
    this.context.commit(Mutations.SET_SEARCHING_FLAG, true);
    ApiService.setHeader();
    const query = "latestestimatetables";
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RECENT_UPDATED_TYPE_DOCS, data.items);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error while loading updated estimate tables.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SEARCH_DOCUMENTS](param: IDocumentSearchParam) {
    this.context.commit(Mutations.SET_ERROR, []);
    this.context.commit(Mutations.SET_SEARCHING_FLAG, true);
    if (!param) {
      return;
    }

    let query = "?";
    if (param.term) {
      query += "term=" + param.term;
    }

    if (param.tickerSymbol) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "tickerSymbol=" + param.tickerSymbol;
    }

    if (param.publicationTypes) {
      for (let i = 0; i < param.publicationTypes.length; i++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "publicationTypeId=" + param.publicationTypes[i];
      }
    }

    if (param.documentEditorView) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "documentEditorView=" + param.documentEditorView;
    }

    if (param.includeOnHold) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "includeOnHold=" + param.includeOnHold;
    }

    if (param.includeHidden) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "includeHidden=" + param.includeHidden;
    }

    if (param.onlyOnHold) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "onlyOnHold=" + param.onlyOnHold;
    }

    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "startDate=" +
        new Date(
          Date.UTC(
            param.startDate.getFullYear(),
            param.startDate.getMonth(),
            param.startDate.getDate(),
            0,
            0,
            0,
            0
          )
        ).toISOString();
    }

    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query +=
        "endDate=" +
        new Date(
          Date.UTC(
            param.endDate.getFullYear(),
            param.endDate.getMonth(),
            param.endDate.getDate(),
            23,
            59,
            59,
            999
          )
        ).toISOString();
    }

    if (param.sectors) {
      for (let i = 0; i < param.sectors.length; i++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "sectorId=" + param.sectors[i];
      }
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.order) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.order;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENT_SEARCH_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error searching for publications.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DOWNLOAD_DOC](doc: IDocument) {
    this.context.commit(Mutations.SET_LIMITED_DOWNLOAD, false);
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const query =
      doc.documentId +
      "/download/?source=web&token=" +
      (store.getters.currentUser as IUser).linkToken;
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        var atData = Uint8Array.from(atob(data.content), (c) =>
          c.charCodeAt(0)
        );
        var blob = new Blob([atData], { type: doc.mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error downloading the publication.",
          ]);
          return;
        }
        if (response.status === 429) {
          this.context.commit(Mutations.SET_LIMITED_DOWNLOAD, true);
          return;
        } else if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DOWNLOAD_SHARED_DOC](param) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!param.documentId || !param.token || !param.shareId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error downloading the publication.",
      ]);
    }
    ApiService.setHeader();
    const query =
      param.documentId +
      "/shared?token=" +
      param.token +
      "&shareId=" +
      param.shareId;
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        var atData = Uint8Array.from(atob(data.content), (c) =>
          c.charCodeAt(0)
        );
        var blob = new Blob([atData], { type: data.mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error downloading the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DOWNLOAD_DOC_EF](param) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!param.documentId || !param.token) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error downloading the publication.",
      ]);
    }
    ApiService.setHeader();
    const query =
      param.documentId +
      "/download?token=" +
      param.token +
      "&source=" +
      param.source;
    return ApiService.get(Endpoints.Documents, query)
      .then(({ data }) => {
        var atData = Uint8Array.from(atob(data.content), (c) =>
          c.charCodeAt(0)
        );
        var blob = new Blob([atData], { type: data.mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", data.fileName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error downloading the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.LOAD_DOCUMENT](documentId: string) {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    return ApiService.get(Endpoints.Documents, documentId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.CREATE_DOCUMENT](document: IDocument) {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const req = {
      title: document.title,
      fileName: document.fileName,
      notAvailableUntil: document.notAvailableUntil,
      content: document.content,
      companyId: document.companyId,
      publicationTypeId: document.publicationTypeId,
      exportDocumentId: null,
      publicationDate: document.publicationDate,
      parentDocumentId: document.parentDocumentId,
      chapterNumber: document.chapterNumber,
      onHold: document.onHold,
    } as any;
    return ApiService.post(Endpoints.Documents, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DOCUMENT_ID, data);
        this.context.commit(Mutations.SET_ERROR, []);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error creating the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.UPDATE_DOCUMENT](document: IDocument) {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const req = {
      title: {
        op: "Replace",
        value: document.title,
      },
      fileName: {
        op: "Replace",
        value: document.fileName,
      },
      notAvailableUntil: {
        op: "Replace",
        value: document.notAvailableUntil,
      },
      content:
        document.content && document.content !== undefined
          ? {
              op: "Replace",
              value: document.content,
            }
          : null,
      companyId: {
        op: "Replace",
        value: document.companyId,
      },
      publicationTypeId: {
        op: "Replace",
        value: document.publicationTypeId,
      },
      publicationDate: {
        op: "Replace",
        value: document.publicationDate,
      },
      onHold: {
        op: "Replace",
        value: document.onHold,
      },
    } as any;

    return ApiService.patch(
      Endpoints.Documents + "/" + document.documentId,
      req
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error updating the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.DELETE_DOCUMENT](documentId: string) {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Documents, documentId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error deleting the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.SHARE_DOCUMENT](payload) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!payload || !payload.emailAddress || !payload.documentId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error sharing the publication.",
      ]);
      return;
    }
    ApiService.setHeader();
    const req = {
      emailAddress: payload.emailAddress,
      message: payload.message,
    } as any;
    return ApiService.post(
      Endpoints.Documents + "/" + payload.documentId + "/share",
      req
    )
      .then(({ data }) => {
        //do nothing
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error sharing the publication.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }

  @Action
  [Actions.ADD_DOCUMENT_SECTORS](payload) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!payload || !payload.documentId || payload.sectors.length == 0) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error adding a sector to a publication.",
      ]);
      return;
    }
    ApiService.setHeader();
    for (let index = 0; index < payload.sectors.length; index++) {
      const sectorId = payload.sectors[index];
      ApiService.post(
        Endpoints.Documents + "/" + payload.documentId + "/sectors/" + sectorId,
        payload
      )
        .then(({ data }) => {
          //Do nothing
        })
        .catch(({ response }) => {
          if (!response.data) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error adding a sector to a publication.",
            ]);
            return;
          }
          this.context.commit(Mutations.SET_ERROR, [
            response.data?.errors[0]?.errorMessage,
          ]);
          return;
        });
    }
  }

  @Action
  [Actions.DELETE_DOCUMENT_SECTORS](payload) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!payload || !payload.documentId || payload.sectors.length == 0) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error deleting a sector from a publication.",
      ]);
      return;
    }
    ApiService.setHeader();
    for (let index = 0; index < payload.sectors.length; index++) {
      const sectorId = payload.sectors[index];
      ApiService.delete(
        Endpoints.Documents + "/" + payload.documentId + "/sectors/" + sectorId
      )
        .then(({ data }) => {
          //Do nothing
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [
            response.data?.errors[0]?.errorMessage,
          ]);
          return;
        });
    }
  }

  @Action
  [Actions.LOAD_MINIMAL_DOCUMENTS](param: IMinimalSearchParam) {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!param) {
      return;
    }

    let query = "?";
    if (param.term) {
      query += "term=" + param.term;
    }

    if (param.page != 1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    ApiService.setHeader();
    return ApiService.get(Endpoints.Documents, "minimal/" + query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MINIMAL_DOCUMENTS, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error loading the publications.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
