import {
    IBaseModule,
    IEventDownloadSearchParam,
    ISearchResult,
    IEventDownload
  } from "@/store/common/Types";
  import ApiService from "@/core/services/ApiService";
  import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
  import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
  
  @Module
  export default class EventDownloadModule extends VuexModule implements IBaseModule {
    errors = {};
    eventDownloads = {} as ISearchResult<IEventDownload>;
    eventDownload = {} as IEventDownload;
    eventDownloadId = {};
  
    /**
     * Get Event Download errors
     * @returns array
     */
    get getEventDownloadErrors() {
      return this.errors;
    }  

    /**
     * Get holiday
     * @returns IHoliday
    */
    get getEventDownload(): IEventDownload {
        return this.eventDownload;
    }
    
    /**
     * Get search result
     * @returns ISearchResult<IEventDownload>
     */
    get getEventDownloads(): ISearchResult<IEventDownload> {
      return this.eventDownloads;
    } 

    /**
     * Get newly-created holiday ID
     * @returns string
     */
    get getEventDownloadId() {
        return this.eventDownloadId;
    }

  
    @Mutation
    [Mutations.SET_ERROR](error) {
      this.errors = { ...error };
    }
     
    @Mutation
    [Mutations.SET_EVENT_DOWNLOADS](items: ISearchResult<IEventDownload>) {
      this.eventDownloads = items;
    }   

    @Mutation
    [Mutations.SET_EVENT_DOWNLOAD](eventDownload: IEventDownload) {
      this.eventDownload = eventDownload;
    }

    @Mutation
    [Mutations.SET_EVENT_DOWNLOAD_ID](eventDownloadId: string) {
      this.eventDownloadId = eventDownloadId;
    }
  
    @Action
    [Actions.SEARCH_EVENT_DOWNLOADS](param: IEventDownloadSearchParam) {
      if (!param) {
        return;
      }
  
      let query = "?";
      if (param.page) {
        query += "page=" + param.page;
      } 

      if (param.orderBy) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "orderBy=" + param.orderBy;
      }
  
      ApiService.setHeader();
      return ApiService.get(Endpoints.EventDownloads, query)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT_DOWNLOADS, data);
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error searching for event downloads.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }

    @Action
    [Actions.LOAD_EVENT_DOWNLOAD](eventDownloadId: string) {
      ApiService.setHeader();
      return ApiService.get(Endpoints.EventDownloads, eventDownloadId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_EVENT_DOWNLOAD, data);
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error loading the event download.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }

    @Action
    [Actions.CREATE_EVENT_DOWNLOAD](eventDownload: IEventDownload) {
        ApiService.setHeader();
        const req = {
            link: eventDownload.link,
            fileName: eventDownload.fileName,
            content: eventDownload.content,
        } as any;
        return ApiService.post(Endpoints.EventDownloads, req)
        .then(({ data }) => {
            this.context.commit(Mutations.SET_EVENT_DOWNLOAD_ID, data);
            this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
            if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
                "There was an error creating the event download.",
            ]);
                return;
            }
            if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            }
        });
    }

    @Action
    [Actions.UPDATE_EVENT_DOWNLOAD](eventDownload: IEventDownload) {
      ApiService.setHeader();
      const req = {
        fileName: {
          op: "Replace",
          value: eventDownload.fileName,
        },
        content: {
          op: "Replace",
          value: eventDownload.content,
        },
      } as any;
      return ApiService.patch(Endpoints.EventDownloads + "/" + eventDownload.eventDownloadId, req)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error updating the event download.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }

    @Action
    [Actions.DELETE_EVENT_DOWNLOAD](eventDownloadId: string) {
      ApiService.setHeader();
      return ApiService.delete(Endpoints.EventDownloads, eventDownloadId)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_ERROR, {});
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_ERROR, [
              "There was an error deleting the event download.",
            ]);
            return;
          }
          if (response.data && response.data.errors) {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          }
        });
    }

    @Action
    [Actions.DOWNLOAD_EVENT_DOC](linkName: string) {
        this.context.commit(Mutations.SET_ERROR, []);
        ApiService.setHeader();        
        return ApiService.get(Endpoints.EventDownloads, 'link/' + linkName)
            .then(({ data }) => {
                // Convert the Base64 content to binary data as the browser is limited to the number of characters it can handle.
                var atData = Uint8Array.from(atob(data.content), (c) =>
                  c.charCodeAt(0)
                );
                data.content = {};
                var blob = new Blob([atData]);
                data.blob = blob;
                this.context.commit(Mutations.SET_EVENT_DOWNLOAD, data);
            })
            .catch(({ response }) => {
              if (!response || response.status === 404) {
                this.context.commit(Mutations.SET_ERROR, [
                  "There was an error downloading the publication.",
                ]);
            return;
        }        
      });
  }
  }
